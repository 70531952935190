//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { apiHome } from "@/api/apiHome";
import filterSearchType from "@/api/filterSearchType.js"
import env from "@/../env.js";
import cookie from "@/storage/cookies.js";
import { TabIndexEnum, queryPositionEnum } from "./constants.js";

import NoData from "../noData";
import BiddingBugCell from "./ItemCell/BiddingBugCell";
import WinBiding from "./ItemCell/WinBiding";
// import BiddingHallCell from "./ItemCell/AdvanceNotice";
import TenderItem from "@/pages/Home/components/tender/ItemCell/TenderItem/TenderItem.vue";
import TenderAnnouncement from "@/pages/Home/components/tender/ItemCell/TenderAnnouncement.vue";

import NeedNotice from "./ItemCell/NeedNotice";
import BidInfo from "./ItemCell/BidInfo";
import comTMBSke from "../skeComponents/comTMBSke.vue";
import comTabSke from "../skeComponents/comTabSke.vue";
import comSmallTBCardSke from "../skeComponents/comSmallTBCardSke.vue";
import GoodsPurchase from "./ItemCell/GoodsPurchase";
import EquipmentRent from "./ItemCell/EquipmentRent";

export default {
  data: function () {
    return {
      list: [],
      load: false,
      isLoadData: false,
      isClose: false,
      isMore: false,
      isEmpty: false,
      currentPage: 1,
      tabVale: TabIndexEnum,
      menuList: [],
      menuIndex: 0,
      firstType: "", // 一级菜单
      firstTypeName: ""
    };
  },
  components: {
    NoData,
    WinBiding, // 中标公告
    TenderItem, // 招标大厅
    NeedNotice, // 需求预告
    GoodsPurchase, // 商品采购
    EquipmentRent, // 设备租赁
    TabList: () => import("@/components/TabList/index.vue"),
    FooterLoad: () => import("@/components/FooterLoad/index.vue"),
    Auction: () => import("./ItemCell/auction.vue"),
    comTMBSke,
    comTabSke,
    comSmallTBCardSke
  },
  props: {
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  filters: {},
  async asyncData({ params, error }) {
    // return {};
  },
  watch: {
    activeIndex: {
      handler: function () {
        console.log("我是否触发");
        this.menuIndex = 0;
        this.menuList = [];
        if (this.activeIndex === 3) {
          this.firstType = 1;
          this.firstTypeName = "商品拍卖";
        } else {
          this.firstType = "";
          this.firstTypeName = "";
        }
        console.log(this.activeIndex, 'this.activeIndex-=-=-');
        this.activeIndex !== 3 && this.activeIndex !== 5 && this.getMenuList()
        this.refreshData();
      },
      immediate: true
    }
  },
  methods: {
    // 获取树菜单列表
    async getMenuList() {
      const type = this.activeIndex === TabIndexEnum.purchase ? 2 : 1
      const res = await filterSearchType.getTenderTypeTree({ businessScenario: type }) // 竞价类型
      if (res.data.code !== 0) {
        return this.$message.warning(typeReq.data.msg)
      }
      const list = res.data.result.map(x => {
        return {
          name: x.name,
          value: x.no
        }
      })
      this.menuList = [{ name: '不限', value: '' }, ...list]
    },
    setAuctionItem(item) {
      // let { state, no } = item;
      // // this.list[]
      // let index = 0;
      // this.list.forEach((item, indexs) => {
      //   if (item.no === no) {
      //     index = indexs;
      //   }
      // });
      // if (state == 1) {
      //   this.$set(this.list[index], "state", 2);
      // } else if (state === 2) {
      //   this.$set(this.list[index], "state", 3);
      // }
      // this.list = [];
      // this.currentPage = 1;
      // this.getAuctionList();
    },
    // 刷新
    refreshData() {
      this.isMore = false;
      this.isClose = false;
      this.currentPage = 1;
      this.list = [];
      this.initList();
    },
    // 加载
    loadData() {
      if (this.isLoadData) {
        this.currentPage += 1;
        this.initList();
        this.isClose = true;
        return;
      }
      this.$message.error("没有更多数据了！");
    },
    // 初始化列表数据
    initList() {
      const index = this.activeIndex;
      const isFirstMenu = this.menuList.length;
      switch (index) {
        case 0: case 1: case 2: case 4: // 招标竞价
          this.getListData();
          break;
        case 3: // 拍卖大厅
          this.getAuctionType();
          this.getAuctionList();
          break;
        case 5: // 需求预告
          this.getEngineeringTypeSelect();
          if (this.firstType === '') {
            // 工程项目
            this.getAdvanceList();
          } else if (this.firstType === 0) {
            // 商品采购
            this.getLeaseList('frontCommodityZcDemandListSearchDTO', 'commodityZcDemandListVO');
          } else if (this.firstType === 10) { // 物流货运
            this.getLeaseList('frontLogisticsZcDemandListSearchDTO', 'logisticsZcDemandListVO')
          } else if (this.firstType === 11) { // 劳务工队
            this.getLeaseList('frontLabourZcDemandListSearchDTO', 'labourZcDemandListVO')
          } else if (this.firstType === 9) { // 其他
            this.getLeaseList('frontOtherZcDemandListSearchDTO', 'otherZcDemandListVO')
          } else {
            // 机械租赁
            this.getLeaseList('frontRentZcDemandListSearchDTO', 'rentZcDemandListVO');
          }
          break;
        case 6: // 中标公告
          this.getWinBidList();
          break;
        default:
          break;
      }
    },
    clickTabItem(item) {
      if (this.load) return;
      const { name, value } = item;
      this.firstType = value;
      this.firstTypeName = name;
      this.refreshData();
    },
    // 拍卖大厅筛选
    async getAuctionType() {
      // let res = await apiHome.getAuctionStatus();
      // const { data, code, msg } = res.data;
      // if (+code == 0) {
      //   const list =
      //     data &&
      //     data.map(item => {
      //       return {
      //         value: item.no,
      //         name: item.name
      //       };
      //     });
      setTimeout(() => {
        this.menuList = [
          { name: "商品拍卖", value: 1 },
          { name: "资产处置", value: 2 },
          { name: "司法拍卖", value: 3 },
          { name: "破产拍卖", value: 4 },
          { name: "海关/政府", value: 5 }
        ];
      });
      return;
      // }
    },
    // 拍卖大厅
    async getAuctionList() {
      // /ov1/auction/list
      let currentArea = cookie.cookieRead("area")
        ? JSON.parse(cookie.cookieRead("area"))
        : {};
      let params = {
        limit: 12,
        page: this.currentPage,
        sortMethod: 1,
        type: this.firstType,
        // province: currentArea.id || ''
      };
      let res = await apiHome.getAuctionList(params);
      console.log("resresresres", res);
      const { result, code, msg } = res.data;
      if (+code === 0) {
        const list = result || [];
        this.isLoadData = list.length === params.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < res.data.totalPages;
        this.load = false;
        return;
      }
      this.load = false;
    },
    getEngineeringTypeSelect() {
      setTimeout(() => {
        this.menuList = [
          { name: "工程项目", value: '' },
          { name: "商品采购", value: 0 },
          { name: "机械租赁", value: 1 },
          { name: "设备租赁", value: 2 },
          { name: "车辆租赁", value: 3 },
          { name: "房产租赁", value: 4 },
          { name: "土地租赁", value: 5 },
          { name: "工厂租赁", value: 6 },
          { name: "农机租赁", value: 7 },
          { name: "物流货运", value: 10 },
          { name: "劳务用工", value: 11 },
          { name: "其他", value: 9 },
        ];
      });
    },
    // 需求预告菜单
    async getNoticeMenu() {
      let res = await apiHome.getNoticeMenu();
      const { result, code, msg } = res.data;
      if (+code === 0) {
        const list =
          result.map(item => {
            return {
              ...item,
              name: item.name,
              value: item.name
            };
          }) || [];
        this.menuList = [{ name: "全部", value: "" }, ...list];
        return;
      }
    },
    // 需求预告列表
    async getNeedList() {
      this.load = true;
      const parmas = {
        page: this.currentPage,
        limit: 12,
        type: this.firstType
      };
      let res = await apiHome.getNeedList(parmas);
      const { result, code, msg } = res.data;
      if (+code === 0) {
        const list = result.list || [];
        this.isLoadData = list.length === parmas.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < result.totalPage;
        this.load = false;
        return;
      }
      this.load = false;
    },
    // 招标竞价列表数据
    async getListData() {
      this.load = true;
      const currentArea = cookie.cookieRead("area")
        ? JSON.parse(cookie.cookieRead("area"))
        : "";
      const parmas = {
        limit: 6,
        sortMethod: 1,
        page: this.currentPage,
        queryPosition: queryPositionEnum[this.activeIndex],
        province: currentArea ? currentArea.id : '',
        typeNos: this.firstType,
        dataSources: 0,
      };
      if ([2, 4].includes(this.activeIndex)) {
        delete parmas.province
      }
      let res = await apiHome.getTenderList(parmas);
      const { result, code, msg, totalPages = 0 } = res.data;
      if (+code === 0) {
        const list = result || [];
        this.isLoadData = list.length === parmas.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < totalPages;
        this.load = false;
        return;
      }
      this.load = false;
    },
    // 中标公告列表
    async getWinBidList() {
      this.load = true;
      const parmas = {
        page: this.currentPage,
        typeNos: this.firstType,
        sortMethod: 1,
        limit: 12
      };
      let res = await apiHome.getbidNoticeList(parmas);
      // debugger;
      const { result, code, msg, totalPages = 0 } = res.data;
      if (+code === 0) {
        const list = result || [];
        this.isLoadData = list.length === parmas.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < totalPages;
        this.load = false;
        return;
      }
      this.load = false;
    },
    // 招标预告列表
    async getAdvanceList() {
      this.load = true;
      const parmas = {
        page: this.currentPage,
        limit: 6,
        type: ""
      };
      let res = await apiHome.getpreNoticeList(parmas);
      const { result, code, msg, totalPages } = res.data;
      if (+code === 0) {
        const list = result || [];
        this.isLoadData = list.length === parmas.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < totalPages
      }
      this.load = false;
    },
    // 商品采购列表
    async getGoodsPurchaseList() {
      this.load = true;
      const parmas = {
        limit: 6,
        page: this.currentPage,
        type: this.firstType
      };
      let res = await apiHome.getGoodsPurchase(parmas);
      const { result, code, msg } = res.data;
      if (+code === 0) {
        const list = result.records || [];
        this.isLoadData = list.length === parmas.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        this.isMore = this.currentPage < result.pages;
      }
      this.load = false;
    },
    // 劳务列表
    async getLabourDemand() {
      this.load = true;
      const params = {
        limit: 6,
        page: this.currentPage,
        type: this.firstType
      };
      let res = await apiHome.getLabourDemand(params);
      console.log(res, 'res-==-00999');
      const { result, code, msg, total } = res.data;
      if (+code === 0) {
        const list = result || [];
        this.isLoadData = list.length === params.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        let allPage = Math.ceil(total / params.limit);
        this.isMore = this.currentPage < allPage;
      }
      this.load = false;
    },
    // 其他列表
    async getOtherDemand() {
      this.load = true;
      const params = {
        limit: 6,
        page: this.currentPage,
        type: this.firstType
      };
      let res = await apiHome.getOtherDemand(params);
      console.log(res, 'res-==-00999');
      const { result, code, msg, total } = res.data;
      if (+code === 0) {
        const list = result || [];
        this.isLoadData = list.length === params.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        let allPage = Math.ceil(total / params.limit);
        this.isMore = this.currentPage < allPage;
      }
      this.load = false;
    },
    // 物流列表
    async getWuliuList() {
      this.load = true;
      const params = {
        limit: 6,
        page: this.currentPage,
        type: this.firstType
      };
      let res = await apiHome.getLogisticsDemand(params);
      console.log(res, 'res-==-00999');
      const { result, code, msg, total } = res.data;
      if (+code === 0) {
        const list = result || [];
        this.isLoadData = list.length === params.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        let allPage = Math.ceil(total / params.limit);
        this.isMore = this.currentPage < allPage;
      }
      this.load = false;
    },
    // 需求预告 -- 租赁
    async getLeaseList(key, resKey) {
      let demandType = "";
      console.log('this.firstType', this.firstType)
      switch (this.firstType) {
        case 0:
          demandType = 3;
          break;
        case 1:
          demandType = 1;
          break;
        case 2:
          demandType = 5;
          break;
        case 3:
          demandType = 12;
          break;
        case 4:
          demandType = 11;
          break;
        case 5:
          demandType = 21;
          break;
        case 6:
          demandType = 22;
          break;
        case 7:
          demandType = 23;
          break;
        case 10:
          demandType = 19;
          break;
        case 11:
          demandType = 2;
          break;
        case 9:
          demandType = 20;
          break;
        default:
          break;
      }

      this.load = true;
      const params = {
        demandType,
        [key]: {
          limit: 6,
          page: this.currentPage,
        }
      };
      try {
        let res = await apiHome.getLeaseList(params);
        const { result, code } = res.data;
        if (+code === 0) {
          const list = result[resKey].records || [];
          this.isLoadData = list.length === 6;
          this.list = this.list.concat(list);
          this.isEmpty = this.list.length === 0;
          let allPage = Math.ceil(result[resKey].total / 6);
          this.isMore = this.currentPage < allPage;
        }
        this.load = false;
      } catch (error) {
        this.load = false;
      }
    },
    // 需求预告 -- 劳务用工
    async getLabourDemandList() {
      this.load = true;
      const params = {
        limit: 6,
        page: this.currentPage,
        type: this.firstType
      };
      let res = await apiHome.getLabourDemandList(params);
      const { result, code, msg, total } = res.data;
      if (+code === 0) {
        let list = result || [];
        this.isLoadData = list.length === params.limit;
        list = list.map(item => {
          // if(item.)
          return {
            ...item,
            goodsHeader: [
              {
                translate: "工种分类",
                name: "secondTypeName"
              },
              {
                translate: "用工人数",
                name: "workersNumber"
              },
              {
                translate: "工作内容",
                name: "workContent"
              },
              {
                translate: "进场时间",
                name: "mobilizationTime"
              },
              {
                translate: "工期",
                name: "duration"
              }
            ]
          };
        });
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        let allPage = Math.ceil(total / params.limit);
        this.isMore = this.currentPage < allPage;
        console.log("this.isMore", this.isMore, allPage, total);
      }
      this.load = false;
    },
    // 需求预告 -- 物流货运
    async getLogisticsDemandList() {
      this.load = true;
      const params = {
        limit: 6,
        page: this.currentPage,
        type: this.firstType,
        province: ""
      };
      let res = await apiHome.getLogisticsDemandList(params);
      const { result, code, msg, total } = res.data;
      if (+code === 0) {
        let list = result || [];
        this.isLoadData = list.length === params.limit;
        list = list.map(item => {
          // if(item.)
          return {
            ...item,
            goodsHeader: [
              {
                translate: "货物类型",
                name: "firstTypeName"
              },
              {
                translate: "装货地",
                name: "loadAddress"
              },
              {
                translate: "收货地",
                name: "destAddress"
              },
              {
                translate: "需求时间",
                name: "logisticsEndTime"
              },
              {
                translate: "预估运输量",
                name: "totalWeight"
              }
            ]
          };
        });
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        let allPage = Math.ceil(total / params.limit);
        this.isMore = this.currentPage < allPage;
      }
      this.load = false;
    },
    // 需求预告 -- 物流货运
    async getOtherDemandList() {
      this.load = true;
      const params = {
        limit: 6,
        page: this.currentPage,
        type: this.firstType,
        province: ""
      };
      let res = await apiHome.getOtherDemandList(params);
      const { result, code, msg, total } = res.data;
      if (+code === 0) {
        let list = result || [];
        list = list.map(item => {
          let header =
            (item.inventoryList[0] && item.inventoryList[0]["customHeader"]) ||
            [];
          header = header.map(items => {
            return {
              translate: items.header,
              name: items.content
            };
          });
          return {
            ...item,
            goodsHeader: header.concat([
              {
                translate: "需求时间",
                name: "demandTime"
              },
              {
                translate: "预算量",
                name: "amountBudget"
              }
            ])
          };
        });
        console.log("list", list);
        this.isLoadData = list.length === params.limit;
        this.list = this.list.concat(list);
        this.isEmpty = this.list.length === 0;
        let allPage = Math.ceil(total / params.limit);
        this.isMore = this.currentPage < allPage;
      }
      this.load = false;
    },

    todetail(item) {
      const url_zhaocai = env[process.env.environment].VUE_APP_TENDERURL
      if ([0, 1].includes(this.activeIndex)) {
        const { id, state, typeName, dataSources, businessType, type, no } = item;
        console.log('dataSources', id, state, typeName, dataSources, type)
        // return 
        if (+state === 8) {
          // 待报名
          if (+dataSources === 2) {
            window.open(`${url_zhaocai}/tenderDetails?id=${id}`);
          } else {
            window.open(`${url_zhaocai}/hotTenderAdvanceLaborDetail?no=${id}`);
          }
        } else if (+state === 2 && dataSources != 2) {
          console.log('type', type)
          if (businessType == 3) {
            if (type && type == 3) {
              window.open(url_zhaocai + "/companyDemandDetail" + "?no=" + id);
            } else if (type && type == 2) {
              window.open(url_zhaocai + "/companyDemandDetail" + "?no=" + id + "&&type=10");
            } else if (type && type == 19) {
              window.open(url_zhaocai + "/companyDemandDetail" + "?no=" + id + "&&type=9");
            } else if (type && type == 20) {
              window.open(url_zhaocai + "/companyDemandDetail" + "?no=" + id + "&&type=8");
            } else if (type && type == 4) {
              window.open(`${url_zhaocai}/demandNoticeDetail?id=${id}`);
            } else {
              window.open(url_zhaocai + "/companyDemandDetail" + "?no=" + id);
            }
          } else {
            window.open(
              `${url_zhaocai}/CompeteBiddingDetail?no=${id}&typeName=${typeName}`
            );
          }
        } else if ((+state == 9 || +state == 2 || +state == 4) && +dataSources == 2) {
          window.open(`${url_zhaocai}/tenderDetails?id=${id}`);
        } else {
          window.open(`${url_zhaocai}/hotTenderMachineLaborDetail?no=${id}`, '_blank');
        }
        return;
      }
      if (this.activeIndex === 2) {
        // 竞价采购
        const { id, typeName } = item;
        window.open(
          `${url_zhaocai}/CompeteBiddingDetail?no=${id}&typeName=${typeName}`
        );
        return;
      }
      if (this.activeIndex === 3) {
        // 拍卖大厅
        const { no } = item;
        window.open(`${url_zhaocai}/auctionDetail?no=${no}`);
        return;
      }
      if (this.activeIndex === 4) {
        const { id, state, dataSources } = item;
        // if (+state === 1) {
        if (+dataSources === 2) {
          window.open(`${url_zhaocai}/tenderDetails?id=${id}`);
        } else if (+state != 2) {
          // 待报名
          window.open(`${url_zhaocai}/hotTenderAdvanceLaborDetail?no=${id}`);
        }
        return;
      }
      if (this.activeIndex === 5) {
        // 需求预告
        const { id, no, type } = item;
        if (this.firstType === '') { // 工程项目
          window.open(`${url_zhaocai}/demandNoticeDetail?id=${id}`);
        } else if (this.firstType === 0) {
          // 商品采购
          window.open(`${url_zhaocai}/companyDemandDetail?no=${no}`);
        } else if (this.firstType == 9) {
          window.open(`${url_zhaocai}/companyDemandDetail?no=${no}&type=8`);
        } else if (this.firstType == 10) {
          //物流货运
          window.open(`${url_zhaocai}/companyDemandDetail?no=${no}&type=9`);
        } else if (this.firstType == 11) {
          // 劳务用工
          window.open(`${url_zhaocai}/companyDemandDetail?no=${no}&type=10`);
        } else {
          // 租赁
          window.open(`${url_zhaocai}/companyDemandDetail?no=${no}`);
        }
        return;
      }
      if (this.activeIndex === 6) {
        // 中标公告
        const { tenderNo, tenderState, tenderStateName } = item;
        window.open(`${url_zhaocai}/tendereeDetail?id=${tenderNo}`);
        return;
      }
    },
    /**@name 返回锚点 */
    goBack() {
      document.querySelector("#biddingBack").scrollIntoView(true);
      this.getbidList(true);
    }
  },
  created() {
    // this.getbidList();
    this.$store.dispatch("setTenderType");
  },
  mounted() { }
};
