export const TabIndexEnum = {
    hall: 0, // 招标大厅
    // invitBid: 1, // 火热招标
    tenderPublic: 1, // 招标公告
    purchase: 2, // 直接竞价
    auction: 3,
    tenderAdvance: 4, // 招标预告
    need: 5, // 需求预告
    winBid: 6, // 中标公告
    // information: 7, // 招标信息
    
}
// 招标竞价ES接口请求位置参数
export const queryPositionEnum = {
    0: 3, // 招标大厅
    1: 5, // 招标公告
    2: 10, // 动态竞价
    4: 14, // 招标预告
    // 0: 3, // 招标大厅
}
// 招标类型枚举
export const bidTypeEnum = {
    1: '机械租赁',
    2: '劳务分包',
    3: '商品/材料',
    4: '专业工程',
    5: '设备租赁',
    6: '勘察设计',
    7: '建筑设计',
    8: '工程监理',
    9: '工程造价咨询',
    10: '工程总承包',
    11: '房产租赁',
    12: '车辆租赁'
}
// 招标预告根据菜单名称枚举不通列表标题
export const advanceTitleEnum = {
    1: ['类别','机械名称','规格型号','数量'],
    2: ['类别','所属工程','工程量'],
    3: ['类别','材料名称','规格型号','数量'],
    4: ['类别','工程名称','工程量'],
    5: ['类别','设备名称','规格型号','数量'],
    11: ['房产类型','面积','使用地址', '租赁时长'],
    12: ['车型','数量','租赁时长', '座位数']
}
// 招标方式枚举
export const verticalTypeEnum = {
    1: '资格预审',
    2: '资格后审',
    3: '报价即报名',
}
// 招标方式
export const biddingMethodEnum = {
    1:'公开招标',
    2:'邀请招标'
}
// 招标状态
export const stateEnum = {
    1: {name:'即将招标', className: 'state-1', timeLabel: '投标开始时间：', timeField: 'bidStartTime'},
    2: {name:'竞价中', className: 'state-2', timeLabel: '投标结束时间：', timeField: 'applyEndTime'},
    3: {name:'竞标评标', className: 'state-3', timeLabel: '开标时间：', timeField: 'openBidTime'},
    4: {name:'招标定标', className: 'state-4', timeLabel: '定标时间：', timeField: 'calibrationTime'},
    6: {name:'正在招标', className: 'state-6', timeLabel: '投标结束时间：', timeField: 'bidEndTime'},
    8: {name:'招标预告', className: 'state-8', timeLabel: '报名开始时间：', timeField: 'applyStartTime'},
    9: {name:'招标报名', className: 'state-9', timeLabel: '报名结束时间：', timeField: 'applyEndTime'},
    // 以下状态后台数据会过滤掉
    5: {name:'废标', className: '', timeLabel: ''},
    7: {name:'流标', className: '', timeLabel: ''},
    10: {name:'资格审查中', className: '', timeLabel: ''},
    20: {name:'审批中', className: '', timeLabel: ''},
    21: {name:'审批不通过', className: '', timeLabel: ''},
}
// 中标公示-中标公告标题枚举
export const ornumEnum = {
    1: '公示中',
    2: '已废标',
    3: '已结束',
}
// 竞价采购根据菜单名枚举不同列表标题
export const buyTitleEnum = {
    1: ['类别','机械设备名称','规格型号','数量','最低报价(元)'],
    2: ['类别','所属工程','分项工程名称','工程量','最低报价(元)'],
    3: ['类别','材料名称','规格型号','数量','最低报价(元)'],
    4: ['类别','工程名称','工程量','项目特征描述','最低报价(元)'],
}
// 竞价根据状态显示标题和时间
export const bidTitleAndTime = {
    2: {name:'', timeLabel: '截止时间', timeField: 'applyEndTime'},
    3: {name:'', timeLabel: '定标时间', timeField: 'calibrationTime'},
    5: {name:'', timeLabel: '截止时间', timeField: 'applyEndTime'},
}