//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CommonTag from "../commonTag";
import Bidding from "./bidding";
export default {
  components: {
    CommonTag, //切换tag
    Bidding //竞价采购
  },
  data: function() {
    return {
      activeIndex: 0,
      // '招标大厅',
      //   '动态竞价',
      //   '招标公告',
      //   '拍卖大厅',
      //   '招标预告',
      //   '中标公告',
      //   '需求预算'
    };
  },

  async asyncData({ params, error }) {
    // return {};
  },
  methods: {
    changeTag(index) {
      this.activeIndex = index;
    }
  },
  created() {},
  mounted() {}
};
