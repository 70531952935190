var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"goods"},[_c('div',{attrs:{"id":"biddingBack"}}),_vm._v(" "),_c('common-tag',{attrs:{"title":'招标/竞价',"tagList":[
      '招标大厅',
      '招标公告',
      '动态竞价',
      '拍卖大厅',
      '招标预告',
      '需求预算',
      '中标公告'
    ],"activeIndex":_vm.activeIndex},on:{"changeTag":_vm.changeTag}}),_vm._v(" "),_c('bidding',{attrs:{"activeIndex":_vm.activeIndex}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }